import axios from 'axios'
import toast from 'react-hot-toast'

export const handleError = (error) => {
  let errorMessage = '';
  if (error.response?.status === 401) {
    errorMessage = 'Unauthorized. Please login again.';
  } else if (error.response?.data?.errors?.full_messages) {
    errorMessage = error.response.data.errors.full_messages.join('. ');
  } else if (error.response?.data?.errors) {
    errorMessage = error.response.data.errors.join('. ');
  } else {
    errorMessage = 'An error occurred';
  }
  toast.error(errorMessage);
};
export const handleSuccess = (message) => {
  toast.success(message || 'Success!')
}

// Make payments api
export const getClientSecret = (product, price, ipAddress, customField, isPayPal) => {
  let queryParams = `product=${product}&user_ip=${ipAddress}`;
  if (isPayPal) {
    queryParams += '&method=paypal';
  }

  const isPriceValid = (price) => /^-?\d+(\.\d+)?$/.test(price);

  if (price && isPriceValid(price)) {
    // Convert to number, fix to 2 decimal places, and convert back to string
    const roundedPrice = parseFloat(price).toFixed(2);
    queryParams += `&price=${roundedPrice}`;
  }

  if (customField) {
    queryParams += `&custom_field=${customField}`;
  }

  return axios.get(`/get_client_secret?${queryParams}`);
}

// Complete PayPal transaction

export const completePayPalTransaction = (client_secret) => {
  return axios.post('/complete_paypal_order', { order: client_secret });
}