// Function to fetch IP address
export const fetchIpAddress = async () => {
  try {
    const ipResponse = await fetch('https://api.ipify.org?format=json');
    if (ipResponse.ok) {
      const ipData = await ipResponse.json();
      return ipData.ip;
    } else {
      return '';
    }
  } catch (error) {
    console.error('Failed to fetch IP address:', error);
    return '';
  }
};
