import React from 'react'
import initializeApp from './app/init';
import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import axios from 'axios'
import Pay from './pages/Pay/Pay'
import PayPal from './pages/PayPal/PayPal'
import Success from './pages/Success'

axios.defaults.headers = {
  'Content-Type': 'application/json',
  // "Access-Control-Allow-Origin": "*",
}

initializeApp()

function App() {
  return (
    <>
      <Router>
        <Routes>
          <Route path="/pay" element={<Pay />} />
          <Route path="/paypal" element={<PayPal />} />
          <Route path="/success" element={<Success />} />
        </Routes>
      </Router>
    </>
  )
}

export default App
