import React, { useState, useEffect } from "react";
import {
  PaymentElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import { CgSpinnerAlt } from 'react-icons/cg'
import * as Yup from 'yup';
import { useFormik } from 'formik';
import toast from 'react-hot-toast'
import { errorToSlack } from "../../utils/errorToSlack";


export function CheckoutForm(clientSecret) {
  const stripe = useStripe();
  const elements = useElements();
  const [isLoading, setIsLoading] = useState(false);
  const [isElementReady, setIsElementReady] = useState(false);

  const validationSchema = Yup.object().shape({
    email: Yup.string().email('Invalid email').required('Email is required'),
    name: Yup.string().required('Name is required'),
  });

  const formik = useFormik({
    initialValues: {
      email: '',
      name: '',
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      handleSubmit(values);
    },
  });

  useEffect(() => {
    if (elements) {
      const paymentElement = elements.getElement(PaymentElement);
      if (paymentElement) {
        paymentElement.on('ready', () => {
          setIsElementReady(true);
        });
      }
    }
  }, [elements]);

  useEffect(() => {
    if (!stripe) {
      return;
    }
    const client_secret = clientSecret.clientSecret
    if (!client_secret) {
      return;
    }
    stripe.retrievePaymentIntent(client_secret);
  }, [stripe]);

  const successUrl = `${window.location.origin}/success`;
  // const success_ = clientSecret.itemData.success_redirect || successUrlOrigin
  // const error_ = clientSecret.itemData.failure_redirect

  const handleSubmit = async ({ email, name }) => {
    if (!stripe || !elements) {
      return;
    }
    setIsLoading(true);
    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: successUrl,
        payment_method_data: {
          billing_details: {
            email,
            name
          }
        },
      },
    });
    if (error.type === "card_error" || error.type === "validation_error") {
      toast.error(error.message)
      // errorToSlack(`Error on checkout form. ${error.type}, ${error.message}`)
    } else {
      toast.error("An unexpected error occurred.")
      errorToSlack(`Error on checkout form. ${error.type}, ${error.message}`)
    }
    setIsLoading(false);
  };

  const paymentElementOptions = {
    layout: "tabs"
  }

  return (
    <>
      <form id="payment-form" onSubmit={formik.handleSubmit}>
        <PaymentElement id="payment-element" options={paymentElementOptions} />
        {isElementReady ? (
          <>
            <div className="mb-4">
              <label htmlFor="email" className="block text-sm font-medium text-gray-700">Email</label>
              <input
                type="email"
                id="email"
                placeholder="Email"
                value={formik.values.email}
                onChange={formik.handleChange}
                className={`border rounded-md px-3 py-2 focus:outline-none focus:border-indigo-600 w-full  ${formik.touched.email && formik.errors.email && 'border border-red-600 leading-none'}`}
              />
              {formik.errors.email && formik.touched.email && <div className="text-sm text-red-600">{formik.errors.email}</div>}
            </div>
            <div className="mb-4">
              <label htmlFor="name" className="block text-sm font-medium text-gray-700">Full Name</label>
              <input
                type="text"
                id="name"
                placeholder="Name"
                value={formik.values.name}
                onChange={formik.handleChange}
                className={`border rounded-md px-3 py-2 focus:outline-none focus:border-indigo-600 w-full  ${formik.touched.name && formik.errors.name && 'border border-red-600 leading-none'}`}
              />
              {formik.errors.name && formik.touched.name && <div className="text-sm text-red-600">{formik.errors.name}</div>}
            </div>
            <button disabled={isLoading || !stripe || !elements} id="submit"
              className="mt-6 flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 disabled:bg-gray-200 disabled:cursor-wait"
            >
              <span id="button-text">
                {isLoading || !stripe || !elements ? (
                  <CgSpinnerAlt
                    className="mr-3 h-5 w-5 animate-spin"
                    viewBox="0 0 24 24"
                  ></CgSpinnerAlt>)
                  : "Pay now"}
              </span>
            </button>
          </>
        ) : <div className="flex">
          <span className="loading loading-spinner text-primary mx-auto"></span>
        </div>}
      </form >
      <div className="max-w-md mx-auto text-center mt-6">
        <img src="/SecureCode.png" className="w-28 inline-block" alt="" />
        <img src="/Verified.png" className="w-20 inline-block" alt="" />
      </div>
    </>
  );
}