import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import SuspenseContent from './containers/SuspenseContent';
import { Toaster } from 'react-hot-toast'

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
  <Suspense fallback={<SuspenseContent />}>
    <App />
    <Toaster position="bottom-center" reverseOrder={false} />
  </Suspense>
  // </React.StrictMode>
);
reportWebVitals();
