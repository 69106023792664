import axios from 'axios';

const slackErrorChannelUrl = process.env.REACT_APP_SLACK_ERRORS_CHANEL_WEBHOOK_URL;

export const errorToSlack = async (error) => {
  const proxyUrl = 'https://cors-anywhere.herokuapp.com/';
  const url = proxyUrl + slackErrorChannelUrl;
  const data = new URLSearchParams();
  data.append('text', error);

  try {
    await axios.post(url, data, {
      mode: 'no-cors',
    });
  } catch (error) {
    console.error(error.message);
  }
};
