import axios from "axios"

const initializeApp = () => {
  if (process.env.NODE_ENV === 'production') {
    // For production environment
    axios.defaults.baseURL = process.env.REACT_APP_PROD_API_URL;
  } else {
    // For development environment
    axios.defaults.baseURL = process.env.REACT_APP_DEV_API_URL;
  }
};
export default initializeApp