import React, { useEffect } from "react";

import { BiCheckCircle } from "react-icons/bi";

function SuccessPage() {
  const successURL = localStorage.getItem('success');

  useEffect(() => {
    if (successURL !== 'null') {
      const redirectTimeout = setTimeout(() => {
        window.location.href = successURL;
      }, 3000); 
      return () => clearTimeout(redirectTimeout);
    }
  }, [successURL]);

  return (
    <div className="min-h-screen bg-base-200 flex items-center">
      <div className="card mx-auto w-full max-w-5xl shadow-xl">
        <div className="hero min-h-full rounded-l-xl bg-white">
          <div className="hero-content py-12 flex flex-col">
            <BiCheckCircle size={90} color="#16a34a" />

            <div className="sm:mx-auto sm:w-full sm:max-w-sm">
              <h2 className="text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
                <span className="text-green-600">Payment completed!</span>
              </h2>
              <div className="text-center mt-8">
                {successURL !== 'null' && (
                  <a href={successURL} className="text-indigo-600 font-bold">{successURL}</a>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SuccessPage;
